@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url('./fonts/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-display: swap;
}

@font-face {
  font-family: 'BentonSansCond';
  src: local('BentonSansCond'), url('./fonts/BentonSansCond-Regular.ttf') format('truetype');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BentonSansCond';
  src: local('BentonSansCond'), url('./fonts/BentonSansCond-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'BentonSansCond';
  src: local('BentonSansCond'), url('./fonts/BentonSansCond-Bold.ttf') format('truetype');
  font-weight: bold;
  font-display: swap;
}

html, body {
  margin: 0;
  font-family: 'BentonSansCond', 'OpenSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 16px;
}

a:link, a:visited, a:active {
  text-decoration: none;
  text-decoration-color: inherit;
}

input:-webkit-autofill {
  border-radius: inherit;
  box-shadow: 0 0 0 30px #FFF inset;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background-color: #E4E4E6;
  border-radius: 50px;
}
*::-webkit-scrollbar-thumb {
  background-color: #0B5D8F;
  border-radius: 50px;
  background-clip: content-box;
  border: 3px solid transparent;
}