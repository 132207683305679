.fontWeight--normal {
    font-weight: 400;
}

.fontWeight--medium {
    font-weight: 500;
}

.fontWeight--semiBold {
    font-weight: 600;
}

.fontWeight--bold {
    font-weight: 700;
}

.fontWeight--bolder {
    font-weight: 800;
}